import styled from 'styled-components';

export type StyledProjectCardProps = {
  projectColor: string;
}

export const StyledProjectCard = styled.article`
  gap: .5rem;
  display: grid;
  cursor: pointer;
  transition: all .3s ease-in-out;
  grid-template-columns: auto 1fr;
  border: 1px dotted var(--border-color);
  background-color: var(--white);
  padding: 1rem;

  @for $i from 1 through 20 {
    &:nth-child(#{$i}){
      transition-delay: $i*0.1s;
    }
  }

  .image-wrapper {
    background-color: ${(props: StyledProjectCardProps) => props.projectColor};
    .cover-image { width: 25rem !important; }
  }

  .content {
    padding: 1rem 0;
    padding-left: 1.75rem;

    .tags {
      font-weight: 600;
      font-size: 0.65rem;
      margin-bottom: .5rem;
      text-transform: uppercase;
      .tag {
        color: var(--blue);
        margin-right: 1rem;
      }
    }

    .name {
      margin: 0;
      font-size: 1.5rem !important;
      font-weight: 700;
      font-size: 1.75rem;
      font-family: var(--bold);
      text-transform: capitalize;
    }

    .description {
      margin: 0;
      line-height: 1.9;
      overflow: hidden;
      font-size: .85rem;
      margin-top: .75rem;
      color: #6b6b6b;
    }

    .project-stack {
      gap: 1.25rem;
      display: flex;
      margin-top: .75rem;
      align-items: center;

      .skill {
        display: flex;
        align-items: center;
        border-radius: 0.25rem;
        justify-content: center;
        background: transparent !important;
      }

      .icon {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
      }

    }

    .links {
      margin-top: 2rem !important;
      .link-wrapper {
        margin-top: 0.75rem !important;
      }
    }

  }

  &:hover {
    overflow: hidden;
    border-radius: 2px;
    box-shadow: var(--shadow);
    background-color: var(--white);
  }

  &.is-inview {
    > * {
      animation: reveal .8s var(--easing);
    }
  }

  @media only screen and (max-width: 1024px) {
    gap: 0;
    padding: 0;
    height: auto;
    border: none;
    grid-template-columns: 1fr;

    &:last-child { border: none; padding-bottom: 0 }

    .image-wrapper {
      border-radius: .25rem;
      border: 1px solid ${(props: StyledProjectCardProps) => props.projectColor};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0;
      .cover-image {
        width: 100% !important;
        height: 12rem !important;
      }
    }

    .content {
      padding: 1.25rem 1rem;
      border-radius: .25rem;
      border: 1px solid var(--border-color);
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-top: 0;

      .tags, .project-stack {
        display: none !important;
      }

      .name {
        margin: 0.25rem 0 0;
        font-size: 1.25rem !important;
      }

      .description {
        height: auto;
        line-height: 1.95;
        font-size: 0.95rem;
      }

      .links {
        margin-top: 0.75rem;

        .link-icon {
          height: 1.2rem;
          font-size: 1.2rem;
          margin-right: .45rem;
        }

        .link-text {
          font-size: 0.95rem;
          margin-top: 0.05rem;
        }

        .link-wrapper {
          margin-top: 0.5rem;
        }

      }

    }

    }

  }
`;

export const StyledLinks = styled.div`
  margin: 0;

  .link-icon {
    height: 1.05rem;
    font-size: 1.05rem;
    margin-right: .35rem;
    &:last-child {
      display: none;
      margin-right: 0;
      margin-left: .35rem;
    }
  }

  .link-text {
    line-height: 1;
    font-weight: 600;
    font-size: 0.8rem;
    margin-top: 0.1rem;
  }

  .link-wrapper {
    display: inline-flex;
    margin-top: 0.25rem;
    align-items: center;
    text-transform: uppercase;
    &:hover {
      color: var(--blue);
      .link-icon:last-child {
        display: block;
      }
    }
  }
`
