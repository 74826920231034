import jsonSchemaRaw from 'assets/files/structured-schema.json';
import defaultImage from 'assets/images/seo-banner.png';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from "react-helmet";
import { JsonSchema } from "utils/types";

type HeaderProps = {
  description?: string,
  image?: string,
  tags?: string[],
  title?: string,
}

type SiteMetadata = {
  site: {
    metadata: {
      title: string;
      siteUrl: string;
      description: string;
      author: string;
    }
  }
}



const Head: React.FC<HeaderProps> = ({ description, tags = [], title, image }) => {

  const { pathname } = useLocation();

  const { site }: SiteMetadata = useStaticQuery(
    graphql`
      query {
        site {
          metadata: siteMetadata {
            title
            siteUrl
            description
            author
          }
        }
      }
    `
  );

  const siteUrl = site.metadata?.siteUrl;
  const defaultTitle = site.metadata?.title;
  const metaImageUrl = `${siteUrl}${defaultImage}`;
  const metaDescription = description || site.metadata.description;
  const jsonSchema: JsonSchema = jsonSchemaRaw;

  const seo = {
    title: !!title ? `${title} | ${defaultTitle}` : defaultTitle,
    keywords: Array.from(new Set([...defaultKeywords, ...tags])),
    description: metaDescription,
    image: image || metaImageUrl,
    url: `${siteUrl}${pathname}`,
  }

  jsonSchema.headline = seo.title;
  jsonSchema.description = seo.description;

  return (
    <Helmet htmlAttributes={{ lang: `en` }}>

      <title>{seo.title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />

      <meta name="keywords" content={seo.keywords.join(',')} />

      <script type='application/ld+json'>
        {JSON.stringify(jsonSchema)}
      </script>

    </Helmet>
  )
}

function useLocation() {
  if (typeof window === 'undefined') return { pathname: '' };
  const { pathname } = window?.location ?? { pathname: '' }
  return { pathname };
}

const defaultKeywords = ['react', 'react-native', 'web development', 'web developer',
  'app and website development', 'android with react native', 'anthony mwangi',
  'anthony mwangi web developer', 'web developer in kenya', 'software engineer',
  'anthony mwangi software engineer', 'front end developer']

export default Head;
