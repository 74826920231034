import { GatsbyImage as Img, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

declare interface Props {
  className?: string;
  src: IGatsbyImageData;
  alt?: string;
}

const Image: React.FC<Props> = (props) => {
  const { src, alt = 'image', className = '' } = props;
  const image = getImage(src);

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={alt}
      image={image}
      objectFit='contain'
      className={`${className}`}
    />
  )
}

export default Image;
