import { Link } from 'components';
import React from 'react';

type MenuLinkProps = {
  to: string;
  caption: string;
  onClick?: () => void;
}

const MenuLink: React.FC<MenuLinkProps> = ({ onClick, caption='Link', to='' }) => {
  return (
    <li>
      <Link className='menu-link' to={to} title={caption}>
        <button onClick={onClick}>{caption}</button>
      </Link>
    </li>
  )
}

export default MenuLink;
