import React, { useCallback, useMemo } from 'react';
import { HiOutlineArrowNarrowRight, HiOutlineExternalLink } from 'react-icons/hi';
import { ProjectLinks } from 'utils/types';
import { StyledLinks } from './styles';

type Props = {
  data: ProjectLinks;
  projectType: string
}

const StyledProjectLinks: React.FC<Props> = ({ data, projectType }) => {

  const liveLinkCaption = useMemo(() => {
    if (projectType === 'website') return 'VIEW LIVE WEBSITE'
    if (projectType === 'mobile app') return 'DOWNLOAD MOBILE APP'
  }, [projectType]);

  const linkCaption = useCallback((linkType) => {
    switch (linkType) {
      case 'live': return liveLinkCaption;
      case 'design': return 'VIEW DESIGN';
      case 'internal': return 'VIEW PROJECT';
      default: return 'EXTERNAL LINK'
    }
  }, []);

  const links = useMemo(() => {
    const linkTypes = Object.keys(data);
    const parsedLinks = linkTypes.map(linkType => {
      const url = data[linkType];
      const label = linkCaption(linkType);
      const target = linkType === 'internal' ? '_self' : '_blank';
      return { url, label, target };
    });
    return parsedLinks.filter(a => !!a.url); // only display links that have a url
  }, [data]);

  return (
    <StyledLinks className='links'>
      {
        links.map((link) =>
          <div key={link.label} className="flex-wrapper">
            <a href={link.url} target={link.target} className="link-wrapper" key={link.label}>
              <HiOutlineExternalLink className='link-icon' />
              <div className="link-text">{link.label}</div>
              <HiOutlineArrowNarrowRight className='link-icon' />
            </a>
          </div>
        )
      }
    </StyledLinks>
  );

}

export default StyledProjectLinks;
