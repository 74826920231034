import { useMemo } from "react";
import { StyledGrid } from "./components";

type GridProps = {
  columns?: number,
  theme?: 'light' | 'dark',
}

export default function Grid(props: GridProps) {
  const { columns = 6, theme = 'light' } = props;
  const color = useMemo(() => theme === 'light' ? '#e7e7e7' : '#000', [theme]);
  return (
    <StyledGrid className="column-lines w-row" color={color}>
      {
        Array(columns).fill(0).map((_, i) => (
          <div key={i} className="line w-col w-col-2" />
        ))
      }
    </StyledGrid>
  )
};
