import styled from 'styled-components';

export const StyledGrid = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100%;
  display: grid;
  position: absolute;
  grid-template-columns: repeat(6, 1fr);

  .line {
    height: 100%;
    min-height: 100vh;
    padding-right: 0;
    padding-left: 0;
    border-left: 1px dashed transparent;
    border-color: ${(props: { color: string }) => props.color};
    min-height: 1px;
  }
`
