import { Link } from 'gatsby';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: flex;
  width: 2.5rem;
  color: var(--blue);
  height: 2.25rem;
  font-size: 0.9rem;
  font-weight: 900;
  align-items: center;
  border-radius: 0.05rem;
  justify-content: center;
  font-family: var(--bold);
  background-color: var(--white);
  transition: all 0.3s ease-in-out;
  &:hover { background: var(--yellow) }
`
