import { useMemo } from 'react';

const Experience: React.FC = () => {
  const myExperience = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return (currentYear - 2017) - 1;
  }, []);
  return <b>{myExperience}+ years</b>;
}

export default Experience;
