import navHeaderPattern from 'assets/images/pattern.svg'
import { AppLogo } from 'components'
import React, { useCallback } from 'react'
import ContactInformation from 'views/footer/contacts'
import MenuLink from './menu-link'
import { MenuButton, StyledHeader, StyledNav } from './styles'

const NavMenu: React.FC = () => {

  const [menuState, setMenuState] = React.useState('closed');

  const toggleMenu = useCallback(() => {
    const newState = menuState !== 'open' ? 'open' : 'closed';
    document.documentElement.classList.remove(`menu-${menuState}`);
    document.documentElement.classList.add(`menu-${newState}`);
    return setMenuState(newState);
  }, [menuState]);

  return (
    <StyledHeader id="app-menu">

      <MenuButton className="menu-icon" onClick={toggleMenu}>
        <span>hidden text</span>
      </MenuButton>

      <StyledNav className={`${menuState}`} background={navHeaderPattern}>

        <section className="menu-content">

          <AppLogo/>

          <div className="menu-title">
            <span>M</span>
            <span>E</span>
            <span>N</span>
            <span>U</span>
          </div>

          <ContactInformation/>

          <ul className='menu-items'>
            <MenuLink onClick={toggleMenu} to='/#skills' caption='skills' />
            <MenuLink onClick={toggleMenu} to='/#projects' caption='projects' />
            <MenuLink onClick={toggleMenu} to='/#about' caption='about me' />
            <MenuLink onClick={toggleMenu} to='/#contacts' caption='contact me' />
          </ul>

        </section>

      </StyledNav>

    </StyledHeader>
  )
}

export default NavMenu;
