import React from "react";
import 'theme/main.scss';

declare interface LayoutProps {
  children: React.ReactNode | React.ReactNode[]
}

const Main: React.FC<LayoutProps> = ({ children }) => {
  return (
    <main id='app'>
      <>{children}</>
      <div id="cursor" />
    </main>
  )
}

export default Main;
