import { Image } from 'components';
import React from 'react';
import { Skill } from 'utils/types';

type Props = {
  data: Skill;
}

const SkillIcon: React.FC<Props> = ({ data }) => {
  const { name = '', image, color = '#464CAC' } = data;
  return (
    <div className='skill' title={name} style={{ background: `${color}` }}>
      <Image className='icon' src={image} alt={`${name} icon`} />
    </div>
  );
}

export default SkillIcon;
