import Styled from 'styled-components';

export const StyledFooter = Styled.footer`
  padding: 1rem;
  background: linear-gradient(
    180deg,
    var(--accent-color) 40%,
    var(--bg-dark-color) 0
  );

  @media only screen and (max-width: 1024px) {
    padding: 5rem 5rem 2rem;

    .section-wrapper {
      max-width: 30rem !important;
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 0;
  }
`

export const ContactSection = Styled.div`
  width: 100%;
  display: grid;
  gap: 1.5rem 2.5rem;
  position: relative;
  margin-bottom: 1.5rem;
  padding: 4rem 2rem 3rem;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-1);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);

  .section-heading {
    grid-column: 1/-1;
  }

  .section-description {
    margin: 0;
    max-width: 22rem;
    color: var(--copy-light-color);
    font-size: .85rem;
  }

  @media only screen and (max-width: 720px) {
    gap: 1.5rem;
    border-radius: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);

    .section-description {
      font-size: 0.95rem;
      line-height: 1.65rem;
    }
  }

  @media only screen and (min-width: 721px) and (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);

    .section-description {
      max-width: none;
    }
  }
`

export const StyledContactInfo = Styled.div`
  .contact {
    display: grid;
    column-gap: 1.25rem;
    align-items: center;
    margin-bottom: 1rem;
    grid-template-columns: auto 1fr;

    .contact-icon {
      display: flex;
      font-size: 1.25rem;
      color: var(--blue);
    }

    .contact-info {
      margin: 0;
      font-size: .9rem;
      color: var(--dark);
    }
  }
`;

export const FooterMenu = Styled.div`
  padding: 1.5rem 0;

  .spacer {
    &:before {
      content: "•";
      display: inline-block;
      margin: 0 1rem .2rem;
      color: var(--white);
      font-size: 0.5rem;
      opacity: .5;
    }
  }

  .menu-items {
    margin: 0;
    opacity: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(60px);
    transition: opacity 0.6s var(--easing), transform 0.6s var(--easing);
  }

  .menu-link {
    font-size: .7rem;
    font-weight: 500;
    color: var(--white);
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    &:hover { color: var(--yellow) }
  }

  &.is-inview {
    .menu-items {
      opacity: 1;
      transform: none;
      transition-delay: 0.4s;
    }
  }

  @media only screen and (max-width: 720px) {
    .menu-items {
      flex-direction: column;
    }
  }
`
