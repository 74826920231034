import { Link as DefaultLink } from 'gatsby';
import React from 'react';
import scrollToElement from 'scroll-to-element';

declare interface LinkProps {
  className?: string;
  title?: string;
  to: string;
  children?: any;
  download?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
}

export default function Link(props: LinkProps) {

  const {
    target = '_self',
    className = 'default-link',
    title = 'Broken Link',
    to = '/404/',
    children,
    download,
  } = props;

  const anchor = to.split('#')[1];

  const forcedActiveState = () => {
    if (typeof window === `undefined`) return '';
    if (!anchor || window.location.hash !== `#${anchor}`) return '';
    return '';
  }

  return (
    <DefaultLink
      to={to}
      title={title}
      target={target}
      download={download}
      className={`${className} ${forcedActiveState()}`}
    >
      {!!children ? children : title}
    </DefaultLink>
  );

};

export function handleMenuLinkClick(link: string, e: React.MouseEvent<HTMLAnchorElement>) {
  if (typeof window !== 'undefined' && link.includes('#')) {
    const [anchorPath, anchor] = link.split('#');
    if (window.location.pathname === anchorPath) {
      if (!!e) e.preventDefault();
      const offset = !!e ? -20 : 0;
      scrollToElement(`#${anchor}`, { offset });
    }
  }
}
