import styled from 'styled-components';


export const StyledHeader = styled.header`
  position: relative;

  .menu-content {
    height: 100%;
    z-index: var(--z-index-4);
    display: none;
    padding: 4.0rem;
    row-gap: 3.50rem;
    column-gap: 7rem;
    margin-left: 20rem;
    position: relative;
    width: calc(100% - 20rem);
    grid-template-columns: auto 1fr;
    transition: all .5s ease-in-out;
    grid-template-rows: 10.5rem auto;
  }

  .app-logo {
    top: 1.5rem;
    left: 1.5rem;
    position: fixed;
    background-color: var(--white);
    &:hover { background: var(--yellow) }
  }

  .menu-title {
    height: auto;
    display: flex;
    line-height: 1;
    font-weight: 700;
    grid-column: 1/-1;
    font-size: 10.5rem;
    text-transform: uppercase;
    span {
      margin: 0;
      padding: 0;
      line-height: 1;
    }
  }

  .menu-items {
    margin: 0;
    grid-row: 2;
    display: flex;
    grid-column: 2;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: square;
    justify-content: flex-start;
    .menu-link {
      display: inline-block;
      margin-bottom: 1rem;
      button {
        line-height: 1;
        text-align: left;
        font-weight: 700;
        font-size: 1rem;
        color: var(--dark);
        padding: 0.25rem 1rem;
        background: transparent;
        font-family: var(--bold);
        text-transform: capitalize;
        text-transform: uppercase;

        &:hover {
          color: var(--blue);
          background: var(--yellow);
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .menu-content {
      gap: 0rem;
      width: 100%;
      margin-left: 0;
      margin-top: 4.5rem;
      padding: 2rem 2.5rem;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
    }

    .app-logo {
      top: 1.5rem;
      left: 2.5rem;
      width: 2.75rem;
      height: 2.4rem;
    }

    .menu-title {
      height: auto;
      grid-column: 1;
      font-size: 3.5rem;
    }

    .menu-items {
      margin: 0;
      grid-row: 2;
      grid-column: 1;
      padding: 0;
      padding-left: 1.25rem;
      li {
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .menu-link {
        margin-bottom: 0;
      }
    }

    .contact-information {
      grid-row: 3;
      margin-top: .15rem;
      padding-left: 0rem;
      padding-top: 2.5rem;
      border-top: .5px solid var(--blue);
    }

  }

  @media only screen and (max-width: 720px) {
    .menu-content {
      padding: 2rem 1rem;
    }
    .app-logo {
      top: 1.25rem;
      left: 1.0rem;
    }
  }

`;

export const StyledNav = styled.nav`
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: var(--z-index-3);
  position: fixed;
  pointer-events: none;
  background: var(--white);
  transition: all .5s ease-in-out;

  &::before {
    top: 0;
    left: 0;
    height: 0;
    content: "";
    width: 20rem;
    display: block;
    position: absolute;
    background-size: 80%;
    background-position: bottom;
    transition: all .5s ease-in-out;
    background-color: var(--blue);
    background-image: url(${(props: { background: string }) => props.background});
  }

  html.menu-open & {
    height: 100vh;
    pointer-events: all;
    &::before { height: 100vh }
    .menu-content {
      display: grid;
      background-size: 20rem;
      > * {
        opacity: 1;
        transform: none;
        transition-duration: 0.8s;
      }
    }
  }

  html.menu-closed & {
    height: 0vh;
    pointer-events: none;
    .menu-content {
      display: grid;
      > * {
        opacity: 0;
        transform-origin: center top;
        transform-style: preserve-3d;
        transform: translateY(100%) rotateX(-80deg);
        transition: opacity 0s var(--easing), transform 0s var(--easing);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    &::before {
      width: 100%;
      bottom: auto;
      background-size: 10rem;
      border-top: 1px solid var(--white);
    }

    html.menu-open & {
      &::before { height: 4.5rem }
    }
  }

`;

export const MenuButton = styled.button`
  top: 1.5rem;
  right: 2.5rem;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  cursor: pointer;
  position: fixed;
  border-radius: 2px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 2px solid var(--white);
  z-index: var(--z-index-5);
  background: var(--blue);

  span { display: none }

  &::before {
    margin-bottom: .3rem;
  }

  &::before, &:after {
    content: "";
    width: 1.3rem;
    height: 0.1rem;
    display: inline-block;
    background: var(--white);
    transition: transform .25s;
  }

  html.menu-open & {
    background: var(--bg-dark-color);
    border-color: transparent;

    &::before {
      margin-bottom: .2rem;
      transform: translateY(5px) rotate(45deg);
    }

    &::after {
      margin-top: 0.2rem;
      transform: translateY(-5px) rotate(-45deg);
    }

  }

  @media only screen and (max-width: 1024px) {
    border: 1px solid var(--white);

    html.menu-open & {
      background: var(--white);

      &::before, &:after {
        background: var(--bg-dark-color);
      }

      &::after {
        margin-top: 0.3rem;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    top: 1.25rem;
    right: 1.25rem;
  }
`;
