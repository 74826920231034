import { ChatIcon, GithubIcon, LinkedinIcon, MailIcon } from 'components/common/icons';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import { StyledContactInfo } from './components';

const ContactInformation: React.FC = () => {
  return (
    <StyledContactInfo className='contact-information'>

      <article className="contact" title='email address'>
        <MailIcon className="contact-icon" />
        <OutboundLink href='mailto:tony.subscryb@gmail.com' className="contact-info">
          tony.subscryb@gmail.com
        </OutboundLink>
      </article>

      <article className="contact" title='phone number'>
        <ChatIcon className="contact-icon" />
        <OutboundLink href='tel:+254 719 236 860' className="contact-info">
          +254 719 236 860
        </OutboundLink>
      </article>

      <article className="contact" title='Linked-In Profile'>
        <LinkedinIcon className="contact-icon" />
        <OutboundLink href='https://www.linkedin.com/in/tony-mwangi' className="contact-info">
          Linked In
        </OutboundLink>
      </article>

      <article className="contact" title='Github Account'>
        <GithubIcon className="contact-icon" />
        <OutboundLink href='https://github.com/AnthonyMwangi' className="contact-info">
          Github
        </OutboundLink>
      </article>

    </StyledContactInfo>
  )
}

export default ContactInformation;
