import { Image } from 'components';
import React, { useMemo } from 'react';
import { StyledComponent } from 'styled-components';
import { Project } from 'utils/types';
import ProjectLinks from './links';
import SkillIcon from './skill';
import { StyledProjectCard, StyledProjectCardProps } from './styles';

type ProjectProps = {
  data: Project,
  styledWrapper?: StyledComponent<'article', any, StyledProjectCardProps>,
}

const ProjectCard: React.FC<ProjectProps> = ({ data, styledWrapper }) => {
  const StyledProject = useMemo(() => !styledWrapper ? StyledProjectCard : styledWrapper, [styledWrapper]);
  return (
    <StyledProject className='project-card' projectColor={data.color} data-track-view>

      <div className="image-wrapper">
        <Image src={data.coverImage} className="cover-image" alt={data.title} />
      </div>

      <div className="content">

        <div className="tags">
          {data.tags.map(tag => <span className='tag' key={tag}>#{tag}</span>)}
        </div>

        <h2 className="name">{data.title}</h2>

        <p className="description">
          {data.description}
        </p>

        <div className="project-stack">
          {
            data.skills.map((skill) => <SkillIcon key={skill.id} data={skill} />)
          }
        </div>

        <ProjectLinks
          data={data.links}
          projectType={data.type}
        />

      </div>

    </StyledProject>
  );

}

export default ProjectCard;
